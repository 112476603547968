@import "./normalize/breakpoints";

a {
  color: rgb(104, 192, 255);
}

.home {
  line-height: 1.5;

  &.recommendations {
    select {
      background-color: rgb(78, 78, 78);
      color: rgb(37, 37, 37);
      pointer-events: none;
    }

    .only-newest {
      opacity: 0.25;
      pointer-events: none;
    }
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 1rem 1rem;
    line-height: 1;

    @include sm() {
      padding: 2rem 1rem 0;
    }

    .heading {
      margin-bottom: 2rem;

      @include sm() {
        margin-bottom: 1rem;
      }

      p {
        opacity: 0.75;
        font-size: 1rem;
        text-transform: uppercase;
        letter-spacing: 0.85rem;
        margin: 0 0 0.5rem 0.2rem;
        padding: 0;
        white-space: nowrap;

        @include sm() {
          letter-spacing: 0.58rem;
        }
      }

      h1 {
        font-size: 3rem;
        margin: 0;
        padding: 0;
        text-transform: uppercase;

        @include sm() {
          font-size: 2.5rem;
        }
      }

      position: relative;

      // span {
      //   background-color: rgb(255, 0, 153);
      //   color: white;
      //   position: absolute;
      //   bottom: -0.85rem;
      //   right: 6%;

      //   @include sm {
      //     right: 5%;
      //   }
      //   font-size: 10px;
      //   display: flex;
      //   padding: 0.35rem 0.35rem 0.15rem 0.35rem;
      //   border-radius: 5px;
      // }
    }

    .filters {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-end;
      max-width: 768px;

      // gap: 1rem;
      gap: 1px;
      row-gap: 1rem;

      label {
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
        width: calc(25% - 1rem);

        // @include lg() {
        //   width: calc(25% - 1rem);
        // }

        @include md() {
          width: calc(33.333333% - 1rem);
        }

        @include sm() {
          width: calc(50% - 1rem);

          &.search {
            width: calc(100% - 2rem);
          }
        }

        span {
          display: block;
          margin-bottom: 0.25rem;
          white-space: nowrap;

          span.badge {
            display: inline-flex;

            background-color: rgb(0, 146, 0);
            font-size: 10px;
            padding: 0.35rem 0.35rem 0.15rem 0.35rem;
            border-radius: 5px;
            text-transform: uppercase;
          }
        }

        select,
        input[type="text"] {
          font-size: 1rem;
          padding: 0.5rem;
          // border-radius: 10px;
          width: auto;
          border: none;
          height: 2.5rem;
          min-width: 8.5rem;
          box-sizing: border-box;

          font-size: 0.9rem;
        }

        &:first-of-type {
          select {
            font-size: 1rem;
            padding: 0.5rem;
            // border-top-left-radius: 10px;
            // border-bottom-left-radius: 10px;
            width: auto;
            border: none;
            height: 2.5rem;

            font-size: 0.9rem;
          }
        }
      }
    }

    .second-row {
      margin-top: 1rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 1rem;

      @include sm {
        margin-top: 1.25rem;
      }

      label.check {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;
        gap: 0.25rem;

        input {
          transform: translateY(-0.1rem);
        }
      }

      button {
        font-size: 0.9rem;
        padding: 0.5rem 1rem;
        // border-radius: 10px;
        // border-top-right-radius: 10px;
        // border-bottom-right-radius: 10px;
        width: auto;
        display: inline-flex;
        height: 2.5rem;
        box-sizing: border-box;
        align-items: center;
        gap: 0.5rem;
        border: none;
        cursor: pointer;
        justify-content: center;
        background-color: rgba(255, 255, 255, 0.1);
        border: 1px solid white;
        color: white;

        // @include md() {
        //   width: calc(33.333333% - 1rem);
        // }

        @include sm() {
          width: calc(50% - 1rem);
        }

        .delete-icon {
          width: 1rem !important;
          height: auto !important;
          fill: white !important;
          overflow: visible;
        }

        p {
          margin: 0;
          padding: 0;
          margin-bottom: -2px;
        }
      }
    }

    /*
    button.recommendations {
      position: fixed;
      right: -4rem;
      top: 2rem;
      padding: 1rem;
      font-size: 14px;
      border: none;
      writing-mode: vertical-lr;
      // text-orientation: upright;
      // border-top-left-radius: 5px;
      // border-bottom-left-radius: 5px;
      background-color: black;
      color: white;
      text-transform: uppercase;
      font-weight: bold;
      cursor: pointer;
      border: 1px solid white;
      border-right: none;
      z-index: 9999;
      box-shadow: 0 0 2rem rgba(0, 0, 0, 1);
      transition: right 0.5s, background-color 0.5s, color 0.5s,
        border-color 0.5s;

      &.selected {
        background-color: rgb(255, 0, 153);
        // color: rgb(255, 0, 153);
        // border-color: rgb(255, 0, 153);

        svg {
          path {
            // fill: rgb(255, 0, 153) !important;
          }
        }
      }

      &.show {
        right: -1px;
      }

      svg {
        width: 1rem;
        height: auto;
        fill: white;
        margin-bottom: 0.5rem;

        path {
          fill: white !important;
        }
      }
    }
    */
  }

  main {
    margin: 0.5rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    min-height: 45vh;

    & > p {
      width: 100vw;
      text-align: center;
    }

    .text-content-wrapper {
      padding: 0;
      margin: auto;

      .text-content {
        text-align: left;
        max-width: 768px;
        background-color: rgba(255, 255, 255, 0.1);
        padding: 2rem 4rem;
        margin: 4rem 1rem 0;
        border-radius: 1rem;
        box-sizing: border-box;

        @include md {
          padding: 1rem;
        }

        ul {
          padding: 0;
          margin: 0 0 0 1rem;
          li {
          }
        }

        h2,
        h3 {
          color: rgb(255, 0, 153);
          font-size: 1.75rem;
          line-height: 1.2;
        }
      }
    }

    .podcasts {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      width: 100%;
    }

    .no-matches {
      text-align: center;
      // line-height: 1.5;

      span {
        display: block;
      }
    }
  }

  footer {
    margin-top: 4rem;
    background-color: black;
    display: flex;
    flex-direction: column;
    padding: 4rem 1rem;
    align-items: center;
    justify-content: center;
    text-align: center;
    // line-height: 1.4;
  }
}
