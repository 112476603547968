@import "../normalize/breakpoints";

$margin: 2rem;

.podcast-item {
  opacity: 0;
  animation-name: scaleIn;
  animation-duration: 0.15s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
  position: relative;
  overflow: hidden;

  width: calc(16.66666% - #{$margin});
  margin: calc(#{$margin} / 2);
  box-sizing: border-box;
  background-color: black;

  overflow: hidden;

  border-radius: 2rem;

  @include xl() {
    width: calc(20% - #{$margin});
  }

  @include md() {
    width: calc(33.3333333% - #{$margin});
  }

  @include sm() {
    width: calc(50% - #{$margin});
  }

  img {
    width: 100%;
    height: auto;
    display: block;

    // border-radius: 2rem;
    transition: transform 0.2s, opacity 0.2s;
  }

  transition: filter 0.2s;

  &:hover {
    filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.25));
    z-index: 999;

    img {
      transform: scale3d(1.1, 1.1, 1);
      opacity: 0.75;
    }
  }

  .recommended {
    background-color: rgb(0, 173, 159);
    color: #eee;
    display: block;
    right: -55px;
    display: block;
    font-weight: 500;
    height: 60px;
    line-height: 60px;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    bottom: -12px;
    transform: rotate(-45deg);
    width: 150px;
    box-shadow: inset 0 0 8px 6px rgba(0, 0, 0, 0.55);

    @include sm() {
      right: -55px;
      height: 50px;
      line-height: 45px;
      bottom: -12px;
      width: 140px;
    }

    svg {
      width: 1.4rem;
      height: auto;
      animation-name: rotating;
      animation-iteration-count: infinite;
      animation-duration: 3000ms;
      animation-timing-function: linear;

      @include sm() {
        width: 1.1rem;
      }

      path {
        fill: white !important;
        // fill: rgb(0, 112, 50) !important;
        // stroke-width: 4px;
        // stroke: white;
      }
    }
  }
}

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.5); // Starts from half the size
    opacity: 0;
  }
  to {
    transform: scale(1); // Scales up to full size
    opacity: 1;
  }
}
